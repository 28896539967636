import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './styles/main.styl'

import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const toastOptions = { position: POSITION.TOP_CENTER }

createApp(App).use(router).use(Toast, toastOptions).mount('#app')
