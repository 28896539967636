import { createRouter, createWebHistory } from 'vue-router'
import customMetaTag from '@/router/router-custom-meta-tag'
const author = 'Abraão Pinto'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      keywords: 'case converter, change case, convert case,uppercase, lowercase, uppercase to lowercase, capitalize, capitalize sentence, capitalize words, convert text, convert case, tools, App, PWA',
      description: 'With Change Case you can easily convert text in different formats, all lowercase letters, all uppercase letters, the first letter of capital sentences and much more, online or offline, on Tablet, Desktop or mobile. Just install PWA. how to convert upper case to lower case. (case converter, change case, convert case,uppercase, lowercase, uppercase to lowercase, capitalize, capitalize sentence, capitalize words, convert text, convert case, tools, App, PWA)',
      author
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      keywords: 'About, change case, author, Abraao Pinto, abraao.dev, App, PWA',
      description: "I'm Abraão Pinto, come to know a little more about me and the technologies used in this PWA site. (case converter, change case, convert case,uppercase, lowercase, uppercase to lowercase, capitalize, capitalize sentence, capitalize words, convert text, convert case, tools, App, PWA)",
      author
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(customMetaTag)

export default router
