const linkUrlCanonical = document.createElement('link')
linkUrlCanonical.setAttribute('rel', 'canonical')

const metaDescription = document.createElement('meta')
metaDescription.setAttribute('name', 'description')
const metaAuthor = document.createElement('meta')
metaAuthor.setAttribute('name', 'author')
const metaKeywords = document.createElement('meta')
metaKeywords.setAttribute('name', 'keywords')

const metaTwitterUrl = document.createElement('meta')
metaTwitterUrl.setAttribute('meta', 'twitter:url')
const metaTwitterTitle = document.createElement('meta')
metaTwitterTitle.setAttribute('meta', 'twitter:title')
const metaTwitterDescription = document.createElement('meta')
metaTwitterDescription.setAttribute('meta', 'twitter:description')

const customMetaTag = (to, from, next) => {
  document.title = `Change Case - ${to.name}`

  linkUrlCanonical.setAttribute('href', `https://changecase.app${to.path}`)
  document.head.appendChild(linkUrlCanonical)

  metaKeywords.setAttribute('content', `${to.meta.keywords}`)
  metaDescription.setAttribute('content', `${to.meta.description}`)
  metaAuthor.setAttribute('content', `${to.meta.author}`)

  document.head.appendChild(metaKeywords)
  document.head.appendChild(metaDescription)
  document.head.appendChild(metaAuthor)

  metaTwitterUrl.setAttribute('content', `https://changecase.app${to.path}`)
  metaTwitterTitle.setAttribute('content', document.title)
  metaTwitterDescription.setAttribute('content', `${to.meta.description}`)
  document.head.appendChild(metaTwitterUrl)
  document.head.appendChild(metaTwitterTitle)
  document.head.appendChild(metaTwitterDescription)

  next()
}

export default customMetaTag
