<template>
  <nav>
      <router-link
    v-for="route, idx in navigationRoutes"
    :key="idx"
    tag="a"
    :to="route.path"
    :label="route.name"
    @click="$emit('item-clicked', e)"
  >{{ route.name }}</router-link>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'MainNav',
  emits: ['item-clicked'],
  setup () {
    const routes = useRouter().getRoutes()
    const navigationRoutes = routes.filter(route => route.name !== undefined)
    return { navigationRoutes }
  }
}
</script>

<style lang="stylus" scoped>
nav
  overflow hidden
  background-color #333
  position sticky;
  position -webkit-sticky
  top 0

nav a
  float left
  display block
  color #f2f2f2
  text-align center
  padding 0.875em 1em
  text-decoration none

nav a:hover
  background-color #ddd
  color black

.router-link-active
.router-link-exact-active
    background-color white
    color black
    opacity 0.9
</style>
