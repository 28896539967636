<template>
  <header>
      <h1>Change Case</h1>
      <p>You can convert your texts from uppercase to lowercase, lowercase to uppercase and so on. Offiline, on mobile, tablet or computer, simply install PWA!</p>
  </header>
</template>

<script>
export default {
  name: 'MainHeader'

}
</script>

<style lang="stylus" scoped>
header
  padding 1.875em
  text-align center
  background white

header h1
  font-size: 3.125em
  padding 0 0 0.5em 0

</style>
