<template>
<main-header />
<main-nav />
<main>
    <router-view />
</main>
<main-footer />
</template>

<script>
import MainHeader from '@/components/layouts/MainHeader'
import MainNav from '@/components/layouts/MainNav'
import MainFooter from '@/components/layouts/MainFooter'
export default {
  name: 'MainLayout',
  components: { MainHeader, MainNav, MainFooter }
}
</script>
