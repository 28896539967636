<template>
  <footer>
    <article>
      <section>
        <span aria-label="Developed by Abraão Pinto">Developed by&nbsp;<b aria-label="author's name">Abraão Pinto</b></span>
        <span>
          <a
            href="https://github.com/abraaopinto"
            target="_blank"
            rel="noopener"
            aria-label="author's GitHub"
          >github</a>
          <a
            href="https://twitter.com/abraaodev"
            target="_blank"
            rel="noopener"
            aria-label="author's Twitter"
          >twitter</a>
          <a
            href="https://br.linkedin.com/in/abra%C3%A3o-pinto-8a1a06170"
            target="_blank"
            rel="noopener"
            aria-label="author's Linkedin"
          >linkedin</a>
        </span>
      </section>
      <section>
        <span><a
            href="https://abraao.dev"
            target="_blank"
            rel="noopener"
            aria-label="author's personal page"
          >abraao.dev</a>&nbsp;©&nbsp;2021</span>
      </section>
    </article>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<style lang="stylus" scoped>
footer
  padding: 1.25em
  text-align center
  background #ddd
  margin-top 1.25em

footer>article
  width 100%
  display flex
  flex-direction column
  gap 1em

footer>article>section:nth-child(1)
  display flex
  flex-direction row
  align-items center
  justify-content space-between
  flex-wrap wrap

@media screen and (max-width: 600px) {
  footer>article>section:nth-child(1) {
    justify-content center
    gap 1em
  }
}

footer>article>section:nth-child(2)
  display flex
  flex-direction row
  align-items center
  justify-content center
  flex-wrap wrap

footer>article>section>span>a
  text-decoration none
  cursor pointer
  padding 0.5em
  color black
  font-weight bold

footer>article>section>span>a:hover
  opacity 0.7
</style>
