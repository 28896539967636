<template>
  <main-layout />
</template>
<script>
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: { MainLayout }
}
</script>
